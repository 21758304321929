<template>
  <v-data-table
    v-if="userId"
    v-bind="dataTableAttrs"
    :headers="headers"
    :items="list"
    :loading="isLoading"
    :options.sync="options"
    :server-items-length="total"
    :items-per-page.sync="itemsPerPage"
    v-model="selectedItems"
    :disable-pagination="true"
    :hide-default-footer="true"
    @update:items-per-page="handleItemsPerPageUpdated"
  >
    <template v-slot:top>
      <rs-table-title-bar
        class="mt-6"
        title="Yönetilen Toplu Yaşam Alanları"
        icon="mdi-home-lightning-bolt-outline"
        :add-route="disabled ? null : '#'"
        @click:add.prevent="handleAddClick"
        @reload="loadList"
        hide-edit
        hide-search
        :show-delete="!disabled"
        :delete-enabled="selectedItems.length > 0"
        hide-export
        hide-import
        @click:delete="handleBatchDeleteClick"
      />

      <AddClusterForm @saved="loadList()" ref="addClusterForm" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.name="{ item }">
      <router-link
        :to="{
          name: 'definitions.clusters.show',
          params: { id: item.id },
        }"
      >
        {{ item.name }}
      </router-link>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.actions="{ item }">
      <rs-table-button-delete
        v-if="!disabled"
        @deleted="handleDeleteClick(item)"
        :loading="isLoading"
        label="Yetkiyi Kaldır"
        confirm-text="Çalışanın yetkisini kaldırmak istediğinizden emin misiniz?"
      />
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.is_active="{ item }">
      <rs-table-cell-boolean :value="item.is_active" />
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.name="{ item }">
      <router-link
        :to="{ name: 'definitions.clusters.edit', params: { id: item.id } }"
      >
        {{ item.name }}
      </router-link>
    </template>
    <!-- eslint-disable-next-line -->
    <!-- <template v-slot:item.bank_integration="{ item }">
      <rs-table-cell-boolean :value="item.bank_integration" />
    </template> -->
    <!-- eslint-disable-next-line -->
    <!-- <template v-slot:item.sms_integration="{ item }">
      <rs-table-cell-boolean :value="item.sms_integration" />
    </template> -->
    <!-- eslint-disable-next-line -->
    <!-- <template v-slot:item.cc_integration="{ item }">
      <rs-table-cell-boolean :value="item.cc_integration" />
    </template> -->
  </v-data-table>
</template>

<script>
import { debounce } from "@/core/plugins/debounce";
import { hasDataTable } from "@/view/mixins";
import AddClusterForm from "@/view/content/workers/AddClusterForm";

export default {
  name: "WorkerClusterList",
  mixins: [hasDataTable],
  components: {
    AddClusterForm,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.loadList();
      }),
      deep: true,
    },
    userId: {
      handler() {
        this.loadList();
      },
    },
  },
  data() {
    return {
      itemsPerPage: 1000,
      headers: [
        {
          text: "TYA Adı",
          value: "name",
          searchable: true,
          sortable: true,
        },
        // {
        //   text: "BB Sayısı",
        //   value: "house_count",
        //   searchable: false,
        //   sortable: true,
        //   align: "right",
        // },
        {
          text: "TYA Durumu",
          value: "is_active",
          searchable: false,
          sortable: true,
          align: "center",
        },
        // {
        //   text: "Kapanış Tarihi",
        //   value: "legal_ending_date",
        //   searchable: false,
        //   sortable: true,
        // },
        // {
        //   text: "Banka Entegrasyonu",
        //   value: "bank_integration",
        //   searchable: false,
        //   sortable: true,
        //   align: "center",
        // },
        // {
        //   text: "SMS Entegrasyonu",
        //   value: "sms_integration",
        //   searchable: false,
        //   sortable: true,
        //   align: "center",
        // },
        // {
        //   text: "Kredi Kartı Entegrasyonu",
        //   value: "cc_integration",
        //   searchable: false,
        //   sortable: true,
        //   align: "center",
        // },
        {
          text: null,
          value: "actions",
          searchable: false,
          sortable: false,
          align: "right",
        },
      ],
    };
  },
  methods: {
    loadList() {
      if (this.isLoading || !this.userId) {
        return;
      }

      this.list = [];
      this.selectedItems = [];
      const params = { company_user_id: this.userId };

      this.isLoading = true;

      this.$api
        .query("company-user-clusters", { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleAddClick() {
      this.$refs.addClusterForm.show(this.userId);
    },
    deleteItem(item) {
      return this.$api.delete(
        `company-user-clusters/${item.company_user_cluster_id}`
      );
    },
    handleDeleteClick(item) {
      if (this.isLoading) {
        return false;
      }
      this.isLoading = false;

      this.deleteItem(item)
        .then(() => {
          this.$toast.success("Yetki kaldırıldı");
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.loadList();
        });
    },
    handleBatchDeleteClick() {
      if (this.isLoading || this.selectedItems.length === 0) {
        return false;
      }

      const promises = [];
      this.isLoading = true;

      for (const index in this.selectedItems) {
        promises.push(this.deleteItem(this.selectedItems[index]));
      }

      Promise.all(promises)
        .then(() => {
          this.$toast.success("Yetkiler kaldırıldı");
        })
        .catch(() => {
          this.$toast.error("Bazı yetkiler kaldırılırken sorun oluştu");
        })
        .finally(() => {
          this.isLoading = false;
          this.loadList();
        });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadList();
    });
  },
};
</script>
