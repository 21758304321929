<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header"> Çalışan - Yönetim Şirketi Eşleştir </template>
    <template slot="body">
      <v-form @submit.prevent="handleFormSubmit" ref="form">
        <v-row cols="12" justify="center">
          <v-col sm="12">
            <rs-select-cluster
              v-model="formData.cluster_id"
              :rules="[rules.required]"
              :company-id="company.id"
              v-if="company"
              required
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          @cancel="handleCancelClick"
          :is-loading="isLoading"
          @submit.prevent="handleFormSubmit"
          hide-submit
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { hasForm } from "@/view/mixins";

export default {
  name: "AddClusterForm",
  mixins: [hasForm],
  props: {
    extraParams: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters(["company"]),
  },
  watch: {
    clusterSearch: debounce(function () {
      this.loadClusterList();
    }, 250),
  },
  data() {
    return {
      isLoading: false,
      formData: {
        cluster_id: null,
        company_user_id: null,
      },
      clusterSearch: null,
      clusterList: [],
    };
  },
  methods: {
    show(companyUserId) {
      this.formData.company_user_id = companyUserId;
      this.formData.cluster_id = null;

      this.$nextTick(() => {
        this.$refs.sidePanel.show();
      });
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;

      this.$api
        .post("company-user-clusters", this.formData)
        .then(() => {
          this.$toast.success(this.$t("saved"));
          this.$emit("saved");
          this.hide();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleCancelClick() {
      this.hide();
    },
    hide() {
      this.formData = {};
      this.$refs.sidePanel.hide();
    },
  },
};
</script>
